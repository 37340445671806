import { Button, Card, Col, Form, message, Row, Spin } from "antd";
import React, { useEffect } from "react";
import AppConfigForm from "../Components/login/AppConfigForm";
import { getUserData } from "../utils/token";
import Layout from "../Layouts/Layout";
import Flex from "../Components/Ui/Flex";
import { addAppSetting } from "../store/api/addAppSetting";
import { fetchAppSetting } from "../store/api/fetchAppSetting";
import { useDispatch, useSelector } from "react-redux";

const AppConfig = () => {
  const dispatch = useDispatch();
  const { data, isLoading } = useSelector((state) => state.appConfigReducer);
  const { isAddingLoading } = useSelector(
    (state) => state.addAppSettingReducer
  );

  useEffect(() => {
    dispatch(fetchAppSetting());
  }, []);

  const onFinish = (values) => {
    const updatedValues = {
      ...values,
      smsOTPOn: values.smsOTPOn ? "1" : "0",
      whatsappOTPOn: values.whatsappOTPOn ? "1" : "0",
      makeAttendanceOnline: values.makeAttendanceOnline ? "1" : "0",
      familyQREnable: values.familyQREnable ? "1" : "0",
    };
    dispatch(addAppSetting(updatedValues))
      .unwrap()
      .then((response) => {
        if (response.Status) {
          message.success("App setting updated successfully!");
        } else {
          message.error(response.Message);
        }
      })
      .catch((error) => {
        message.error("Failed to add app setting. Please try again.");
        console.error("Add app setting error:", error);
      });
  };

  return (
    <Layout>
      <Card>
        {isLoading && !data ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "120px",
            }}
          >
            <Spin size="large" />
          </div>
        ) : (
          <Form
            layout="vertical"
            name="login-form"
            initialValues={
              data
                ? {
                    ...data.Data,
                    smsOTPOn: data.Data.smsOTPOn === "1" ? true : false,
                    whatsappOTPOn:
                      data.Data.whatsappOTPOn === "1" ? true : false,
                    makeAttendanceOnline:
                      data.Data.makeAttendanceOnline === "1" ? true : false,
                    familyQREnable:
                      data.Data.familyQREnable === "1" ? true : false,
                  }
                : {
                    smsOTPOn: true,
                    whatsappOTPOn: true,
                    familyQREnable: true,
                    makeAttendanceOnline: true,
                  }
            }
            onFinish={onFinish}
          >
            <div className="container">
              <Flex
                className="py-2"
                mobileFlex={false}
                justifyContent="between"
                alignItems="center"
              >
                <h2 className="mb-3">App Config Settings</h2>
                <div className="mb-3">
                  <Button
                    type="primary"
                    htmlType="submit"
                    loading={isAddingLoading}
                  >
                    Submit
                  </Button>
                </div>
              </Flex>
            </div>

            <div className="container mt-0">
              <AppConfigForm />
            </div>
          </Form>
        )}
      </Card>
    </Layout>
  );
};

export default AppConfig;
