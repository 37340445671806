import React from "react";
import { Card, Col, Form, InputNumber, Row, Spin, Switch } from "antd";
import { DatabaseOutlined, NumberOutlined } from "@ant-design/icons";

const AppConfigForm = () => {
  return (
    <>
      <Card title="App Config">
        <Row gutter={24}>
          <Col xs={24} sm={24} md={24}>
            <Form.Item
              name="syncMemberLimit"
              label="Fetch Data Limit"
              rules={[
                {
                  required: true,
                  message: "Please enter fetch data limit",
                },
              ]}
            >
              <InputNumber
                style={{ width: "100%" }}
                prefix={<DatabaseOutlined className="text-primary" />}
              />
            </Form.Item>
            <Form.Item
              name="pushAtendedMemberLimit"
              label="Push Data Limit"
              rules={[
                {
                  required: true,
                  message: "Please enter push data limit",
                },
              ]}
            >
              <InputNumber
                style={{ width: "100%" }}
                prefix={<NumberOutlined className="text-primary" />}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={4} key={1} className="pl-4">
            <Form.Item name="smsOTPOn" label="SMS">
              <Switch />
            </Form.Item>
          </Col>
          <Col span={4} key={1} className="pl-1">
            <Form.Item name="whatsappOTPOn" label="Whats App">
              <Switch />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={4} key={1} className="pl-4">
            <Form.Item name="makeAttendanceOnline" label="Online">
              <Switch />
            </Form.Item>
          </Col>
          <Col span={6} key={1} className="pl-1">
            <Form.Item name="familyQREnable" label="Enable Family Member QR">
              <Switch />
            </Form.Item>
          </Col>
        </Row>
      </Card>
    </>
  );
};

export default AppConfigForm;
